import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { saveUserReadArticle } from "./userReadArticlesSlice";
import { saveUserHuddled } from "./userHuddlesSlice";
import { fetchDailyArticle } from "./dailyArticleSlice";
import { WEEKLYSTATS_LISTS } from "@/app/_consts/internal";

const initialState = {
  isLoading: true,
  previousDays: [], // Eventually we will have a leaderboard endpoint that gives us the previous days
  currentDay: {
    // Other parts of the system (user read put, user huddled put, ...) update currentDay
    userReadCounts: {},
    articleReadCounts: {},
    locationReadCounts: {},
    locationHuddleCounts: {},
  },
};

export const fetchWeeklyStats = createAsyncThunk<any, string>(
  "weeklyStats/fetchWeeklyStats",
  async (accessToken) => {
    const response = await axios.get(WEEKLYSTATS_LISTS, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  },
);

const leaderboardSlice = createSlice({
  name: "dailyArticle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeeklyStats.pending, (state) => {
        state.isLoading = true;
        state.previousDays = [];
      })
      .addCase(fetchWeeklyStats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.previousDays = action.payload;
      })
      .addCase(fetchWeeklyStats.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchDailyArticle.pending, (state) => {
        state.isLoading = true;
        state.currentDay.userReadCounts = {};
        state.currentDay.articleReadCounts = {};
        state.currentDay.locationReadCounts = {};
        state.currentDay.locationHuddleCounts = {};
      })
      .addCase(fetchDailyArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentDay.userReadCounts = action.payload.userReadCounts;
        state.currentDay.articleReadCounts = action.payload.articleReadCounts;
        state.currentDay.locationReadCounts = action.payload.locationReadCounts;
        state.currentDay.locationHuddleCounts =
          action.payload.locationHuddleCounts;
      })
      .addCase(fetchDailyArticle.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(saveUserReadArticle.fulfilled, (state, action) => {
        state.currentDay.userReadCounts =
          action.payload.daily_stats_item.userReadCounts;
        state.currentDay.articleReadCounts =
          action.payload.daily_stats_item.articleReadCounts;
        state.currentDay.locationReadCounts =
          action.payload.daily_stats_item.locationReadCounts;
        state.currentDay.locationHuddleCounts =
          action.payload.daily_stats_item.locationHuddleCounts;
      })
      .addCase(saveUserHuddled.fulfilled, (state, action) => {
        if (typeof action?.payload?.daily_stats_item === "object") {
          state.currentDay.userReadCounts =
            action.payload.daily_stats_item.userReadCounts;
          state.currentDay.articleReadCounts =
            action.payload.daily_stats_item.articleReadCounts;
          state.currentDay.locationReadCounts =
            action.payload.daily_stats_item.locationReadCounts;
          state.currentDay.locationHuddleCounts =
            action.payload.daily_stats_item.locationHuddleCounts;
        }
      });
  },
});

export default leaderboardSlice.reducer;
