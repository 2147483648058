import React, { useState } from "react";
import { CameraOutlined, CloseCircleOutlined } from "@ant-design/icons";

const ImageSelect = ({
  children,
  accept,
  onLoadImage,
  onClearImage,
  name,
}: {
  children?: React.ReactNode;
  accept?: string;
  onLoadImage?: (imageData: {
    image: string | ArrayBuffer | null;
    filename: string;
  }) => void;
  onClearImage?: () => void;
  name?: string;
}) => {
  const [image, setImage] = useState("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          if (onLoadImage) {
            onLoadImage({ image: reader.result, filename: file.name });
          }
          setImage(reader.result.toString());

          // Convert ArrayBuffer to Blob
          const blob = new Blob([reader.result], { type: file.type });
          const url = URL.createObjectURL(blob);
          setImage(url);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const removeImage = () => {
    if (onClearImage) {
      onClearImage();
    }
    setImage("");
  };

  return (
    <div className="flex flex-1 justify-center items-center w-full">
      {image ? (
        <div className="relative mt-2">
          {image && (
            // eslint-disable-next-line @next/next/no-img-element
            <img src={image} alt="" className="h-auto w-full" />
          )}
          {Boolean(onClearImage) && (
            <button className="absolute top-2 right-2" onClick={removeImage}>
              <CloseCircleOutlined
                className="text-red-500"
                style={{ fontSize: "24px" }}
              />
            </button>
          )}
        </div>
      ) : (
        <div className="relative w-full border-dashed border-2 border-neutral-400 rounded cursor-pointer flex flex-col gap-2 justify-center items-center pt-6 px-2 pb-8 mt-2 mb-4 bg-neutral-200">
          <input
            name={name}
            type="file"
            accept={accept || "image/jpeg, image/png"}
            onChange={handleFileChange}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
          <CameraOutlined
            className="text-blue-500"
            style={{ fontSize: "48px" }}
          />
          <div className="text-base text-center">
            Click or drag file to this area to upload
          </div>
          {children}
        </div>
      )}
    </div>
  );
};

export { ImageSelect };
