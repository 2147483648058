import React from "react";
import { cn } from "@/app/_utils/ui";

const JoinHuddleIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn("text-red-600 scale-[1.25]", className)}
  >
    <path
      d="M12.298 4.2368C12.3029 3.78926 12.5834 2.49529 11.5197 2.49529H11.5116C11.0641 2.49042 10.6992 2.8504 10.696 3.29632L10.6927 3.84764C10.6749 3.41955 10.3247 3.07741 9.89008 3.07255H9.88197C9.43443 3.06931 9.06959 3.42766 9.06635 3.8752V4.42652C9.06473 4.0633 8.588 3.97736 8.26532 3.97736H8.25559C7.80805 3.97249 7.4432 4.33247 7.43996 4.77839V5.33133M15.7162 16.4923L14.2909 14.2432L16.9178 8.79166C16.9226 8.75761 16.921 8.72356 16.908 8.69113C16.8464 8.53708 16.6421 8.13332 16.1573 8.05549C15.3497 7.92739 15.2038 8.38952 15.066 8.63599L13.9601 10.8672V4.34058C13.9633 3.89304 13.6147 3.5282 13.1672 3.52495H13.1591C12.7115 3.52009 12.3467 3.88007 12.3418 4.32599M9.92576 18.1494C10.6636 17.8105 11.1808 17.0241 11.1808 16.1614V13.7291L11.1727 13.494M11.1727 13.494H11.1678M11.1727 13.494V13.4421L11.1776 13.327L11.1727 13.3156L11.1549 7.53168C11.15 7.08414 10.7868 6.72578 10.3392 6.72902H10.3295C9.90306 6.73389 9.55767 7.06468 9.53011 7.48303V6.62687C9.52362 6.17933 9.15878 5.82097 8.71286 5.82583H8.70313C8.27018 5.8307 7.91993 6.17284 7.9021 6.60092V6.0496C7.89561 5.60206 7.53077 5.24371 7.08485 5.24695H7.07512C6.62758 5.25181 6.26922 5.61504 6.27409 6.06258V6.99495C6.27409 6.99495 6.26598 6.27662 5.44063 6.27662H5.4309C4.98336 6.28148 4.625 6.6447 4.62986 7.09224L4.63959 13.6189C4.63959 13.6189 3.4948 11.4574 3.43642 11.3423C3.37805 11.2271 3.14941 10.6774 2.44243 10.8072C1.95435 10.885 1.75166 11.2887 1.69004 11.4428C1.67707 11.4752 1.65275 11.5125 1.67707 11.5433L1.75652 11.7071L4.30556 16.9949L3.56128 18.1478M6.27409 6.99661V11.9617M3.79964 1.738L5.8979 3.68383M2.14082 3.6076L4.81146 4.63727M17.2648 1.66667L14.706 2.94605M18.3333 3.92541L15.4827 4.16701M7.90534 6.32539V11.9342M9.5074 6.32539V12.0769"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);

const AddHuddlePicIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn("text-red-600 scale-105", className)}
  >
    <path
      d="M16.875 4.84375H14.2188L13.5859 3.07031C13.5422 2.94896 13.4621 2.84407 13.3565 2.76996C13.251 2.69585 13.1251 2.65614 12.9961 2.65625H7.00391C6.74023 2.65625 6.50391 2.82227 6.41602 3.07031L5.78125 4.84375H3.125C2.26172 4.84375 1.5625 5.54297 1.5625 6.40625V15.3125C1.5625 16.1758 2.26172 16.875 3.125 16.875H16.875C17.7383 16.875 18.4375 16.1758 18.4375 15.3125V6.40625C18.4375 5.54297 17.7383 4.84375 16.875 4.84375ZM17.0312 15.3125C17.0312 15.3984 16.9609 15.4687 16.875 15.4687H3.125C3.03906 15.4687 2.96875 15.3984 2.96875 15.3125V6.40625C2.96875 6.32031 3.03906 6.25 3.125 6.25H6.77148L7.10547 5.31641L7.55273 4.0625H12.4453L12.8926 5.31641L13.2266 6.25H16.875C16.9609 6.25 17.0312 6.32031 17.0312 6.40625V15.3125ZM10 7.5C8.27344 7.5 6.875 8.89844 6.875 10.625C6.875 12.3516 8.27344 13.75 10 13.75C11.7266 13.75 13.125 12.3516 13.125 10.625C13.125 8.89844 11.7266 7.5 10 7.5ZM10 12.5C8.96484 12.5 8.125 11.6602 8.125 10.625C8.125 9.58984 8.96484 8.75 10 8.75C11.0352 8.75 11.875 9.58984 11.875 10.625C11.875 11.6602 11.0352 12.5 10 12.5Z"
      fill="currentColor"
    />
    <ellipse cx="10" cy="10.5" rx="3" ry="2.5" fill="none" />
  </svg>
);

const StartHuddleIcon = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn("text-red-600 scale-[1.25]", className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4438 6.53821C8.98237 6.53821 7.79765 7.72292 7.79765 9.18434C7.79765 10.6458 8.98237 11.8305 10.4438 11.8305C11.9052 11.8305 13.0899 10.6458 13.0899 9.18434C13.0899 7.72292 11.9052 6.53821 10.4438 6.53821ZM6.59283 9.18434C6.59283 7.05752 8.31696 5.33339 10.4438 5.33339C12.5706 5.33339 14.2947 7.05752 14.2947 9.18434C14.2947 11.3112 12.5706 13.0353 10.4438 13.0353C8.31696 13.0353 6.59283 11.3112 6.59283 9.18434Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.10516 16.9922C5.10516 14.1405 7.41699 11.8305 10.2669 11.8305V13.0353C8.08213 13.0353 6.30998 14.8061 6.30998 16.9922H5.10516Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.98767 4.51763C1.98767 2.39126 3.70992 0.666672 5.83705 0.666672C7.96379 0.666672 9.688 2.39088 9.688 4.51763H8.48318C8.48318 3.05628 7.29839 1.87149 5.83705 1.87149C4.37609 1.87149 3.19249 3.0559 3.19249 4.51763C3.19249 5.97897 4.37728 7.16376 5.83862 7.16376V8.36858C3.71188 8.36858 1.98767 6.64437 1.98767 4.51763Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 12.3255C0.5 9.47374 2.81183 7.16376 5.66174 7.16376V8.36858C3.47696 8.36858 1.70482 10.1394 1.70482 12.3255H0.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7514 13.0282V17.3333H12.5466V13.0282H13.7514Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3015 15.7832H10.9964V14.5784H15.3015V15.7832Z"
      fill="currentColor"
    />
  </svg>
);

export { JoinHuddleIcon, AddHuddlePicIcon, StartHuddleIcon };
