import React from "react";

const RocketIcon = ({
  fill = "#fff",
  className,
}: {
  fill?: string;
  className?: string;
}) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.52474 22.089C4.6956 23.9182 3.72021 28.3122 3.72021 28.3122C3.72021 28.3122 8.11437 27.3369 9.94351 25.5078C9.95255 25.4987 9.96056 25.4894 9.9695 25.4804L6.55212 22.063C6.54309 22.072 6.53374 22.08 6.52474 22.089Z"
      fill="white"
    />
    <path
      d="M31.5255 0C22.4976 0 14.3785 4.9731 10.2065 12.5919C10.0692 12.0184 10.011 11.4078 10.0366 10.7716C5.73338 11.3082 1.35494 15.156 0.000781242 19.5502C-0.00406393 19.5659 0.0146911 19.5778 0.02773 19.5678C2.72392 17.5076 5.845 16.7224 8.21734 17.4129C8.17276 17.5667 8.12469 17.7188 8.08306 17.8739L8.00705 18.1576L8.2148 18.3654L7.09759 19.4826C7.24588 19.6309 12.5546 24.9396 12.5546 24.9396L13.672 23.8221L13.8786 24.0287C14.1328 23.9603 14.1544 23.9593 14.511 23.8563L14.6009 23.8555C15.2689 26.2177 14.4787 29.2927 12.4322 31.9722C12.4222 31.9853 12.4341 32.0041 12.4498 31.9992C16.8453 30.6447 20.692 26.2652 21.2283 21.9634C20.6017 21.9889 19.9993 21.9323 19.4322 21.7987C21.3908 20.7032 23.1976 19.3742 24.9431 17.6295C29.484 13.0878 32.0342 6.97435 32.0342 0.508713V0H31.5255Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2156 17.6007C20.5546 17.2617 20.7539 16.8331 20.8536 16.3446C20.9035 16.0954 21.6312 12.3273 20.7141 11.4201C19.7969 10.503 16.0288 11.2606 15.7896 11.3005C15.3011 11.4002 14.8725 11.5996 14.5335 11.9385C14.5236 11.9485 14.5136 11.9584 14.4937 11.9684C13.8357 12.6363 13.8357 13.7129 14.5036 14.3708C15.1416 15.0088 16.7565 14.8892 17.0058 15.1384C17.255 15.3876 17.1453 17.0025 17.7833 17.6405C18.4512 18.2985 19.5278 18.2985 20.1857 17.6306C20.1957 17.6306 20.2057 17.6206 20.2156 17.6007Z"
      fill="#BA141A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9185 12.6267C23.5764 12.6666 24.1247 12.4074 24.5832 11.9688C24.643 11.899 24.7028 11.8392 24.7527 11.7794C24.9421 11.5501 25.0717 11.2909 25.1116 10.9918C25.2112 10.3638 24.7826 9.85542 24.1047 9.77567C23.2375 9.67598 22.2705 10.2641 21.9415 11.0716C21.6226 11.8591 22.0612 12.5868 22.9185 12.6267Z"
      fill="#BA141A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5022 15.219C24.7414 14.9099 24.8611 14.571 24.7115 14.1822C24.5919 13.8632 24.3427 13.6838 24.0237 13.5741C23.3359 13.3449 22.4985 13.624 22.0599 14.2121C21.6213 14.8102 21.8306 15.5379 22.5384 15.7772C23.2362 16.0164 23.8343 15.8171 24.3726 15.3485C24.4225 15.3087 24.4623 15.2688 24.5022 15.219Z"
      fill="#BA141A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4879 9.21767C19.448 8.55975 19.7072 8.02144 20.1359 7.55292C20.2057 7.49311 20.2555 7.4333 20.3253 7.38346C20.5545 7.19405 20.8137 7.06446 21.1128 7.01462C21.7408 6.91493 22.2492 7.33361 22.329 8.01147C22.4286 8.86877 21.8505 9.83572 21.053 10.1747C20.2555 10.5136 19.5378 10.065 19.4879 9.21767Z"
      fill="#BA141A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8762 7.58284C17.1952 7.33362 17.5441 7.214 17.9328 7.36353C18.2618 7.48315 18.4512 7.74233 18.5609 8.06133C18.8001 8.75913 18.521 9.61642 17.9229 10.065C17.3148 10.5236 16.5671 10.3043 16.3179 9.58652C16.0787 8.87875 16.278 8.2607 16.7466 7.70246C16.7864 7.66258 16.8263 7.61274 16.8762 7.58284Z"
      fill="#BA141A"
    />
  </svg>
);

export default RocketIcon;
