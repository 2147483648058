import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  COGNITO_CLIENT_ID,
  COGNITO_ENDPOINT,
  MANIFESTO_URL,
} from "@/app/_consts/internal";
import { fetchReadingLists } from "./readingListsSlice";

export const clientId = COGNITO_CLIENT_ID;
export const userPoolUrl = COGNITO_ENDPOINT;
export const tokenEndpoint = `${userPoolUrl}/oauth2/token`;
export const redirectUri = `${MANIFESTO_URL}/loginredirect`;
export const discoveryDocument = {
  authorizationEndpoint: userPoolUrl + "/oauth2/authorize",
  tokenEndpoint: userPoolUrl + "/oauth2/token",
  revocationEndpoint: userPoolUrl + "/oauth2/revoke",
};
export const TOKEN_RESPONSE_CONFIG = "token_response_config";

const initialState = {
  isLoading: true,
  isLoggedIn: false,
  accessToken: null as string | null,
};

export const readUserToken = createAsyncThunk(
  "login/readUserToken",
  async () => {
    if (typeof window !== "undefined") {
      const tokenString = localStorage.getItem(TOKEN_RESPONSE_CONFIG);
      if (!tokenString) {
        return { accessToken: null };
      }
      const config = JSON.parse(tokenString);
      if (config) {
        // Your logic to refresh token if needed
        return { accessToken: config.access_token };
      }
    }
    return { accessToken: null };
  },
);

export const storeLoginResponse = createAsyncThunk(
  "login/storeLoginResponse",
  async (tokenResponse: { accessToken: string }) => {
    if (typeof window !== "undefined") {
      localStorage.setItem(
        TOKEN_RESPONSE_CONFIG,
        JSON.stringify(tokenResponse),
      );
      return { accessToken: tokenResponse.accessToken };
    }
  },
);

export const storeLoginRedirectTokens = createAsyncThunk(
  "login/storeLoginRedirectTokens",
  async (url: string) => {
    const removeDomain = url.split("#").slice(1).join("#");
    const tokens = removeDomain.split("&");
    const config: { [key: string]: string | number | null } = {
      accessToken: null,
      issuedAt: Math.round(new Date().getTime() / 1000),
      idToken: null,
      expiresIn: null,
      tokenType: null,
    };
    tokens.map((token: string) => {
      const spl = token.split("=");
      const id = spl[0];
      const val = isNaN(Number(spl[1])) ? spl[1] : parseInt(spl[1]);
      switch (id) {
        case "access_token":
          config["accessToken"] = val;
          break;
        case "id_token":
          config["idToken"] = val;
          break;
        case "expires_in":
          config["expiresIn"] = val;
          break;
        case "token_type":
          config["tokenType"] = val;
          break;
        default:
          config[id] = val;
      }
    });

    await localStorage.setItem(TOKEN_RESPONSE_CONFIG, JSON.stringify(config));
    return { accessToken: config.accessToken };
  },
);

export const refreshToken = createAsyncThunk(
  "login/refreshToken",
  async (refresh_token: string, { dispatch }) => {
    const body = new URLSearchParams();
    body.append("grant_type", "refresh_token");
    body.append("client_id", COGNITO_CLIENT_ID);
    body.append("refresh_token", refresh_token);

    const response = await fetch(discoveryDocument.tokenEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body,
    });
    const tokenResponse = await response.json();

    // Dispatch the token response to the store
    if (tokenResponse.access_token) {
      tokenResponse.refresh_token = refresh_token;
      dispatch(storeLoginResponse(tokenResponse));
    } else {
      localStorage.removeItem(TOKEN_RESPONSE_CONFIG);
      window.location.reload();
      return;
    }
    return tokenResponse;
  },
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(readUserToken.pending, (state) => {
        state.isLoading = true;
        state.isLoggedIn = false;
        state.accessToken = null;
      })
      .addCase(readUserToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = action.payload.accessToken ? true : false;
        state.accessToken = action.payload.accessToken;
      })
      .addCase(readUserToken.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(storeLoginResponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = action.payload?.accessToken ? true : false;
        state.accessToken = action.payload?.accessToken || null;
      })
      .addCase(storeLoginRedirectTokens.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = action.payload.accessToken ? true : false;
        state.accessToken = action.payload.accessToken
          ? action.payload.accessToken.toString()
          : null;
      })
      .addCase(fetchReadingLists.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(
        refreshToken.fulfilled,
        (state, action: PayloadAction<{ access_token: string }>) => {
          state.isLoading = false;
          state.isLoggedIn = action.payload.access_token ? true : false;
          state.accessToken = action.payload.access_token || null;
        },
      )
      .addCase(refreshToken.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default loginSlice.reducer;
