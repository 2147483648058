import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { DAILY_ARTICLE_URL } from "@/app/_consts/internal";
import { saveUserReadArticle } from "./userReadArticlesSlice";

const initialState = {
  isLoading: true,
  article: {} as any,
  huddleImagePath: undefined as string | undefined,
};

export const fetchDailyArticle = createAsyncThunk<any, string>(
  "dailyArticle/fetchDailyArticle",
  async (accessToken) => {
    const response = await axios.get(DAILY_ARTICLE_URL, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  },
);

const dailyArticleSlice = createSlice({
  name: "dailyArticle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyArticle.pending, (state) => {
        state.isLoading = true;
        state.article = {};
      })
      .addCase(fetchDailyArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.article = {
          ...action.payload.dailyArticle,
          readCount: parseInt(action.payload.dailyArticle.readCount),
        };
        state.huddleImagePath = action.payload.dailyHuddleImage;
      })
      .addCase(fetchDailyArticle.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(saveUserReadArticle.fulfilled, (state, action) => {
        state.article = {
          ...action.payload.daily_stats_item.dailyArticle,
          readCount: parseInt(
            action.payload.daily_stats_item.dailyArticle.readCount,
          ),
        };
      });
  },
});

export default dailyArticleSlice.reducer;
