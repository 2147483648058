import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../reducers/store";
import { RootState } from "../../reducers/store";
import { ImageSelect } from "../forms/image-select";
import { uploadHuddleImage } from "../../reducers/huddleSlice";
import LoadingAnimation from "../graphics/LoadingAnimation";
import { getFormattedDate } from "@/app/_utils/formatter";

interface Huddle {
  huddleTime: string;
  huddleTimeLocal: string;
  huddleId: string;
  Location: string;
}

interface AddHuddleImageFormProps {
  huddle: Huddle;
  onImageUploadStart?: () => void;
  onImageUploadSuccess?: () => void;
  showHuddleInfo: boolean;
}

const AddHuddleImageForm: React.FC<AddHuddleImageFormProps> = ({
  huddle,
  onImageUploadStart = () => {},
  onImageUploadSuccess = () => {},
  showHuddleInfo,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );
  const [uploading, setUploading] = useState(false);

  if (!huddle) return null;

  const onLoadImage = async (imageData: {
    image: string | ArrayBuffer | null;
    filename: string;
  }) => {
    setUploading(true);
    onImageUploadStart();
    const payload = {
      ...imageData,
      accessToken,
      action: "join",
      huddleTime: huddle.huddleTime,
      location: huddle.Location,
      huddleId: huddle.huddleId,
    };
    await dispatch(uploadHuddleImage(payload));
    onImageUploadSuccess();
    setUploading(false);
  };

  return (
    <div
      id="addHuddleImageControl"
      className="flex flex-col items-center justify-center mt-5"
    >
      {showHuddleInfo && (
        <>
          <div className="text-xl font-semibold">{huddle.Location}</div>
          {huddle.huddleTimeLocal && (
            <div className="pb-4">
              {getFormattedDate(new Date(huddle.huddleTimeLocal))}
            </div>
          )}
        </>
      )}
      <ImageSelect
        name="selectHuddleImage"
        onLoadImage={onLoadImage}
        accept="image/jpeg, image/png"
      />
      {uploading && (
        <div className="flex gap-2 pt-4 w-full items-center justify-center">
          <LoadingAnimation>Uploading Image</LoadingAnimation>
        </div>
      )}
    </div>
  );
};

export default AddHuddleImageForm;
