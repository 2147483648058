import React, { useEffect } from "react";
import { Button } from "../buttons/button";
import { CheckOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { cn } from "@/app/_utils/ui";
import ArticleSearch from "../search/ArticleSearch";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../Tooltip";

const HuddleTopicPicker = ({
  articleMode,
  setArticleMode,
  dailyArticle,
  topicArticle,
  vegxHuddleArticleId,
  setTopicArticle,
  dispatch,
  hasHuddledOnVegx,
}: {
  articleMode: "daily" | "pick" | "vegx";
  setArticleMode: (mode: "daily" | "pick" | "vegx") => void;
  dailyArticle: any;
  topicArticle: any;
  vegxHuddleArticleId: string | null;
  setTopicArticle: (article: any) => void;
  dispatch: any;
  hasHuddledOnVegx: boolean;
}) => {
  // Set default to #lifeatveg if vegxHuddleArticleId exists
  useEffect(() => {
    if (vegxHuddleArticleId) {
      dispatch(
        setTopicArticle({
          id: vegxHuddleArticleId,
          title: "#lifeatveg",
        }),
      );
      setArticleMode("vegx");
    }
  }, [vegxHuddleArticleId, dispatch, setArticleMode]);

  return (
    <>
      <div className="w-full">
        <label className="block text-red-secondary font-display uppercase font-semibold text-lg my-2">
          What’s Your Topic?
        </label>
        <div
          className={cn(
            "grid divide-x-2 divide-neutral-400 rounded-full overflow-hidden border border-neutral-400 gap-px bg-neutral-400 transition-opacity duration-300 delay-300 ease-in-out",
            vegxHuddleArticleId ? "grid-cols-3" : "grid-cols-2",
            vegxHuddleArticleId === null
              ? "opacity-0 pointer-events-none"
              : "opacity-100",
          )}
        >
          {vegxHuddleArticleId && (
            <>
              {hasHuddledOnVegx ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        className="w-full border-none rounded-none"
                        variant="outline"
                      >
                        #lifeatveg
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        You’ve run this play already!
                        <br />
                        Call it again next month.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <Button
                  onClick={() => {
                    dispatch(
                      setTopicArticle({
                        id: vegxHuddleArticleId,
                        title: "#lifeatveg",
                      }),
                    );
                    setArticleMode("vegx");
                  }}
                  variant={articleMode === "vegx" ? "gold" : "outlineSquare"}
                  className="w-full border-none rounded-none hover:bg-gold-tint"
                >
                  <CheckOutlined
                    className={cn(articleMode === "vegx" ? "mr-1" : "hidden")}
                  />{" "}
                  #lifeatveg
                </Button>
              )}
            </>
          )}
          <Button
            onClick={() => {
              dispatch(setTopicArticle(dailyArticle));
              setArticleMode("daily");
            }}
            variant={articleMode === "daily" ? "gold" : "outlineSquare"}
            className="w-full border-none rounded-none hover:bg-gold-tint"
          >
            <CheckOutlined
              className={cn(articleMode === "daily" ? "mr-1" : "hidden")}
            />{" "}
            Daily Article
          </Button>
          <Button
            onClick={() => setArticleMode("pick")}
            variant={articleMode === "pick" ? "gold" : "outlineSquare"}
            className="w-full border-none rounded-none hover:bg-gold-tint"
          >
            <CheckOutlined
              className={cn(articleMode === "pick" ? "mr-1" : "hidden")}
            />{" "}
            Pick an Article
          </Button>
        </div>
      </div>
      {articleMode === "pick" && (
        <div className="w-full z-20 relative">
          <ArticleSearch
            onSelectArticle={(article) => {
              dispatch(
                setTopicArticle({
                  id: article.articleId,
                  title: article.title,
                }),
              );
            }}
          />
        </div>
      )}
      {topicArticle && (
        <div className="w-full mt-4">
          {articleMode === "vegx" ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="text-sm font-semibold">
                    #lifeatveg
                    <QuestionCircleOutlined />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="max-w-[290px]">
                    Quarterback once a month on a new topic related to VEG
                    culture, values, and career experiences—share today!
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <div className="text-sm font-semibold">{topicArticle.title}</div>
          )}
        </div>
      )}
    </>
  );
};

export default HuddleTopicPicker;
