import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { USER_HUDDLE_IMAGE_URL } from "@/app/_consts/internal";

export interface Photo {
  approved: boolean;
  articleId: string;
  deleted: boolean;
  huddleId: string;
  huddleTime: string;
  filename: string;
  location: string;
  uploadedAt: string;
  uploadedBy: {
    name: string;
    user_uuid: string;
  };
}

export interface GalleryPhoto extends Photo {
  isLoaded: boolean;
}

interface PhotoGalleryState {
  photos: Photo[];
  isLoading: boolean;
  error: string | null;
}

const initialState: PhotoGalleryState = {
  photos: [],
  isLoading: true,
  error: null,
};

export const fetchPhotos = createAsyncThunk<
  Photo[],
  {
    accessToken: string;
    params: {
      location?: string;
      start?: string;
      end?: string;
      approved?: boolean;
      huddleId?: string;
      huddleTime?: string;
    };
  }
>("photoGallery/fetchPhotos", async ({ accessToken, params }) => {
  const response = await axios.get(USER_HUDDLE_IMAGE_URL, {
    headers: { Authorization: accessToken },
    params,
  });
  return response.data;
});

const photoGallerySlice = createSlice({
  name: "photoGallery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPhotos.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPhotos.fulfilled, (state, action) => {
        state.isLoading = false;
        state.photos = action.payload.map((photo: Photo) => ({
          ...photo,
          deleted: photo.deleted === true,
        }));
      })
      .addCase(fetchPhotos.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default photoGallerySlice.reducer;
