import React from "react";

const PawIcon = ({ fill, className }: { fill: string; className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 15 15"
  >
    <path
      d="M7.501 6.041c-2.326 0-5.6 3.86-5.6 6.3-.025 1.097.758 1.755 2.078 1.755 1.43 0 2.376-.79 3.522-.79 1.157 0 2.107.79 3.522.79 1.292 0 2.102-.657 2.102-1.754-.024-2.437-3.297-6.3-5.624-6.3Zm-4.313-.396c-.304-1.09-1.245-1.797-2.099-1.577-.853.22-1.297 1.281-.993 2.37.304 1.092 1.245 1.8 2.098 1.58.853-.221 1.296-1.284.994-2.373Zm2.482-.654c.904-.256 1.358-1.545 1.01-2.938C6.334.66 5.319-.184 4.41.043 3.505.3 3.051 1.588 3.4 2.981c.348 1.365 1.361 2.268 2.27 2.01Zm8.245-.922c-.853-.192-1.795.487-2.098 1.577-.304 1.09.139 2.15.992 2.37.853.193 1.79-.487 2.099-1.576.302-1.09-.167-2.151-.993-2.371Zm-4.583.922c.882.255 1.923-.645 2.27-2.01.349-1.364-.105-2.682-1.01-2.938-.882-.255-1.923.645-2.27 2.01-.35 1.364.105 2.683 1.01 2.938Z"
      fill={fill}
    />
  </svg>
);

export default PawIcon;
